
import { Columns } from 'vue-columns';
import { Component, Vue, Watch } from 'vue-property-decorator';
import DropdownTitle from '@/components/ui/dropdown-title';
import Icon from '@/components/ui/icon/Icon.vue';
import ProjectsStack from '@/components/documents/projects-stack/ProjectsStack.vue';
import ContextMenuLink from '@/components/context-menu/items/ContextMenuLink.vue';
import { SelectorBarOption } from '@/components/ui/selector-bar';
import SelectorBar from '../settings/components/selector-bar/SelectorBar.vue';
import ProjectsModule from '@/store/modules/projects/ProjectsModule';
import ProjectFilter from '@/components/projects/ProjectFilter.vue';
import UsersModule from '@/store/modules/users/UsersModule';
import { ProjectsSort, SelectedSort } from '@/typings/domain';
import { IShortUser } from '@/models/user';

@Component({
    components: {
        DropdownTitle,
        Columns,
        Icon,
        ProjectFilter,
        ProjectsStack,
        ContextMenuLink,
        SelectorBar,
    },
})
export default class Projects extends Vue {
    public $isMobile: boolean;
    public projectFilters = '';
    public selectedSort = 
    {
        name: '',
        value: '',
    };
    public projectsSort: ProjectsSort = {} as null;
    public selectedFilters: any = {};


    public triggerSwitcher: Array<SelectorBarOption> = [
        {
            title: 'По направлениям',
            count: 12,
            value: 'tags',
            role_id: 1,
        },
        {
            title: 'По клиентам',
            count: 2,
            value: 'clients',
            role_id: 2,
        },
    ];
    created()
    {
        this.projectFilters = this.$route.query.direction as string;
    }

    updateProjects()
    {        
        this.fetchProjects('clients',null,this.projectsSort)
        this.fetchProjects('tags',null, this.projectsSort)
    }

    resetSort()
    {
        this.projectsSort = {} as null;
        this.fetchProjects('clients'),
        this.fetchProjects('tags')
    }
    filterProjectsByUser(user: IShortUser)
    {
        console.log(user);
    }
    sort(sort: ProjectsSort)
    {   
        this.projectsSort = sort;     
        this.fetchProjects('clients', null, this.projectsSort),
        this.fetchProjects('tags', null, this.projectsSort)   
    }
    async mounted() 
    {   
        Promise.all([
            this.fetchProjects('clients'),
            this.fetchProjects('tags'),
        ]).then(() => 
        {
            this.triggerSwitcher[0].count = this.clients.length
            this.triggerSwitcher[1].count = this.projects.length
        })
    }

    get pageRoute()
    {
        return this.$route.query
    }

    @Watch('pageRoute', {deep: true})
    onPageRouteChanged()
    {   
        this.projectFilters = this.$route.query.direction as string
    }

    showCompleteProjects()
    {
        this.selectedSort.name = 'Завершенные проекты'
        this.selectedSort.value = 'complete'
    }
    async fetchProjects(direction?: string | string[], completed?: boolean, sort?: ProjectsSort) {
        await ProjectsModule.fetchProjects(
            { 
                direction: direction, 
                'filter[completed]': completed,
                'sort[order]': sort?.type === 'by' ? 'asc' : null,
                'sort[by]': sort?.type === 'size' ? 'size' : null,
            });
    }

    resetProjectsFilter() {
        this.selectedSort = {} as null;
        this.fetchProjects('tags')
        this.fetchProjects('clients')
    }
    applyFlterByProjects(filterType: SelectedSort) {
        this.selectedSort = filterType;
    }
    get user()
    {
      return UsersModule.currentUser;
    }
    async applyCompleteSort()
    {
        ProjectsModule.clearClients()
        ProjectsModule.clearProjects();
        this.fetchProjects('clients',true)
        this.fetchProjects('tags',true)
    }

    @Watch('selectedSort', {deep: true})
    onSelectedSortUpdated()
    {
        if(this.selectedSort.value === 'complete')
        {
            this.applyCompleteSort()
        }
        if(this.selectedSort.value === 'all')
        {
            this.resetProjectsFilter()
        }
    }

    get companyType()
    {
      if(this.user.general_settings.company_type === 'service' && this.user.general_settings.services_count === 'many')
      {
        return 'many'
      }
      if(this.user.general_settings.company_type === 'service' && this.user.general_settings.services_count === 'one')
      {
        return 'one'
      }
      if(this.user.general_settings.company_type === 'product')
      {
        return 'product'
      }
      return ''
    }
    projectFilterChanged(sortName: string) 
    {
        if(sortName === 'clients' && !this.clients.length)
        {
            this.fetchProjects(sortName)
        }
        if(sortName === 'tags' && !this.projects.length)
        {
            this.fetchProjects(sortName)
        }
        this.projectFilters = sortName
        this.$router.push({ path: `/projects?direction=${sortName}`, replace: true });
    }
    get currentDirection()
    {   
        return this.$route.query.direction === 'clients' ? this.clients : this.projects
    }

    @Watch('projects', {deep: true})
    onProjectsChanged()
    {
        this.triggerSwitcher[1].count = this.projects.length
    }

    @Watch('clients', {deep: true})
    onClientsChanged()
    {
        this.triggerSwitcher[0].count = this.clients.length
    }

    get projects() {
        return ProjectsModule.allProjectsClients;
    }
    get clients()
    {
        return ProjectsModule.allProjects
    }
}



import { Component, Emit, Vue } from 'vue-property-decorator';
import Icon from '@/components/ui/icon/Icon.vue';

@Component({
    components: {
        Icon,
    },
})
export default class ProjectCard extends Vue {
    public projectCardHoverd = false;
    
    public items = 
    [
        {
            id: 1,
            icon: 
            {
                iconColor: '#F9EE8B',
                iconName: 'chat',
            },
            title: 'Чат',
            value: 'chat',
        },
        {
            id: 2,
            icon: 
            {
                iconColor: '#8BF9AA',
                iconName: 'tasks',
            },
            title: 'Задачи',
            value: 'task',
        },
        {
            id: 3,
            icon: 
            {
                iconColor: '#F98B8B',
                iconName: 'knowledge',
            },
            title: 'Знания',
            value: 'knowledge',
        },
        {
            id: 5,
            icon: 
            {
                iconColor: '#fff',
                iconName: 'addtoteam',
            },
            title: 'Добавить в команду',
            value: 'addtoteam',
        },
        {
            id: 6,
            icon: 
            {
                iconColor: '#fff',
                iconName: 'edit',
            },
            title: 'Редактировать',
            value: 'edit',
        },
        {
            id: 7,
            icon: 
            {
                iconColor: '#fff',
                iconName: 'done',
            },
            title: 'Завершить с оценкой',
            value: 'done',
        },
        {
            id: 8,
            icon: 
            {
                iconColor: '#F76747',
                iconName: 'delete',
            },
            title: 'Удалить навсегда',
            value: 'delete',
        },

    ]

    @Emit('cclick')
    itemClicked(item: object)
    {   
        return item
    }

    @Emit('close')
    onClose(){
        return;
    };
}


import { render, staticRenderFns } from "./AddToTeamPopUp.vue?vue&type=template&id=a3e6c234&scoped=true"
import script from "./AddToTeamPopUp.vue?vue&type=script&lang=ts"
export * from "./AddToTeamPopUp.vue?vue&type=script&lang=ts"
import style0 from "./AddToTeamPopUp.vue?vue&type=style&index=0&id=a3e6c234&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3e6c234",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./ProjectTeamList.vue?vue&type=template&id=328a1bfd&scoped=true"
import script from "./ProjectTeamList.vue?vue&type=script&lang=ts"
export * from "./ProjectTeamList.vue?vue&type=script&lang=ts"
import style0 from "./ProjectTeamList.vue?vue&type=style&index=0&id=328a1bfd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "328a1bfd",
  null
  
)

export default component.exports

import Notify from '@/services/helpers/notify';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ProjectsInput extends Vue {
    @Prop() value: string;
    @Prop() placeholder: string;
    @Prop({default: false}) errorResponse: boolean;
    @Prop() required: boolean;
    @Prop({ default: 'rgba(255, 255, 255, 0.15)' }) borderColor: string;

    public innerValue = '';
    public error = false;
    

    get border() {
        return {
            'border': `1px solid ${this.borderColor}`,
        }
    }

    mounted()
    {
        this.error = this.errorResponse;
        if(this.value != undefined)
        {
            this.innerValue = this.value;
        }
    }
    @Emit('submit')
    onSubmit() {        
        if(this.innerValue.length === 0)
        {   
            this.error = true
            Notify.error('Заполните имя клиента')
        }
        else
        {
            this.error = false;
            return this.innerValue;
        }
    }
}

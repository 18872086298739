
import { Component, Emit, Vue } from 'vue-property-decorator';
import Icon from '@/components/ui/icon/Icon.vue';
import ContextPicker from '@/modules/settings/components/context-picker/ContextPicker.vue'
import { IShortUser } from '@/models/user';
import { ProjectsSort, SelectedSort } from '@/typings/domain';
import TeamsModule from '@/store/modules/teams/TeamsModule';
import { IUser } from '@/modules/settings/views/knowledge/model';
import user from '@/api/user/user.api';

@Component({
    components: {
        Icon,
        ContextPicker,
    },
})
export default class ProjectFilter extends Vue {

    public filterOpened = false;
    public remoteTeams = Array<IShortUser>()
    public filterSelectedValue = '';
    public isLoading = false;
    public currentUsersPage = 2;
    public firstSearchUsers = false;
    public selectedSort: ProjectsSort = {} as null;
    get teams()
    {
        return TeamsModule.teams.map(el => {
            return { id: el.id, title: `${el.first_name} ${el.last_name}`, img: el.avatar };
        });
    }
    closeFilter()
    {
        this.filterOpened = false;
    }
    searchUsers(value: {page: number, search: string})
    {
        this.firstSearchUsers = true;
        this.currentUsersPage = 1;
        TeamsModule.clearTeams();
        this.loadUsers(value)
    }

    @Emit('filterProjectsByUser')
    setUsers(users: IShortUser) 
    {
        this.selectedSort.name = users.title;
        this.selectedSort.value = 'byUser'
        this.selectedSort.img = users.img
        this.filterOpened = false;
        return users
    }

    sortProjects(sort: ProjectsSort) {
        this.selectedSort = sort;
        this.onSortSelected();
        this.filterOpened = false;
    }

    paginateUsers(value: {page: number, search: string})
    {
        if(this.firstSearchUsers)
        {
            this.currentUsersPage++;
            value.page = this.currentUsersPage
        }
        this.loadUsers(value);
        this.firstSearchUsers = false
        this.currentUsersPage++;
    }

    @Emit('resetSort')
    onSortReseted()
    {
        return true;
    }
    
    @Emit('sort')
    onSortSelected()
    {
        return this.selectedSort
    }
    async loadUsers(payload?: {page: number, search: string}) {
        this.isLoading = true;
        await TeamsModule.fetchTeams(payload).finally(() => 
        {
            this.isLoading = false;
        });
    }
    resetFilter()
    {
        this.selectedSort.name = '';
        this.selectedSort.value = '';
        this.selectedSort.type = '';
        this.onSortReseted();
    }
    toggleFilter(event: PointerEvent)
    {
        const screenWidth = window.innerWidth;
        this.filterOpened = !this.filterOpened

        if(this.filterOpened)
        {
            this.$nextTick(() => 
            {
                const filterRef = this.$refs.projectFilter as any
                this.setRightPosition(event.clientX, filterRef.offsetWidth, screenWidth)
            })
        }
        
    }
    setRightPosition(clickPos: number, currentWidth: number, screenWidth: number)
    {   
        if(clickPos + currentWidth > screenWidth)
        {
            const filterRef = this.$refs.projectFilter as any
            filterRef.style.right = '-1px';
        }
    }
    @Emit('showCompleteProjects')
    showCompleteProjects()
    {
        this.filterOpened = false;
        return true;
    }
}


import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import CreateClientInput from '@/modules/projects/CreateClient/components/CreateClientInput.vue';
import { IClientSettings, IProjectEdit } from '@/models/newProject'
import ToggleSwitch from '@/components/ui/toggle-switch/ToggleSwitch.vue';
import Notify from '@/services/helpers/notify';
import { ClientsResponse } from '@/models/dto/responses/clients.response';
import ProjectsModule from '@/store/modules/projects/ProjectsModule';


@Component({
    components: { CreateClientInput, ToggleSwitch },
    name: 'ProjectEditor',
})
export default class PrejectEditor extends Vue {
    @Prop() header: string;
    @Prop() settings: IProjectEdit;
    @Prop({default: 'create'}) mode: string
    @Prop({default: {}}) value: ClientsResponse
    public error = false;

    public pageLoaded = false;

    public clientSettings: IClientSettings = {
        name: '',
        general_chat: true,
        general_knowledges: true,
        general_tasks: true,
    };
    
    mounted()
    {
        if(this.mode === 'edit')
        {
            this.fetchClientDetail().finally(() => 
            {
                this.clientSettings = this.onClientSettings;
            })
        }
    }

    async fetchClientDetail()
    {
        await ProjectsModule.fetchClient(this.value.id).finally(() => 
        {
            this.pageLoaded = true;
        })
    }

    get onClientSettings()
    {
        return ProjectsModule.allClientSettings
    }

    @Emit('save')
    submitClient()
    {
        if(this.clientSettings.name === '')
        {
            this.error = true;
            Notify.error('Заполните имя');
        }
        else
        {
            this.error = true;
            return this.clientSettings;
        }
    }

    clientNameValue(clientName: string)
    {
        this.clientSettings.name = clientName;
    }

    @Emit('close')
    closeEditor() {
        return true;
    }
}
